import React, { useState, useEffect } from "react";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { UserAttributes } from "./UserContext";
import ChartComponent from "./ChartComponent";
import {
  Box,
  Container,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { 
  BarChart,
  LineChart,
  BarPlot,
  LinePlot,
  ChartsXAxis,
  ChartsYAxis,
} from '@mui/x-charts';

interface BasicBillInfo {
  year: number;
  month: number;
  basic_price: number;
  total_usage: number;
  electricity_unit_price: number;
  renewable_energy_unit_price: number;
  maintenance_price: number;
  total_price: number;
  unit_per_fee: number;
}

interface Bill {
  room: string | number;
  year: number;
  month: number;
  electric_meter: Array<{
    name: string;
    value: number;
    diff: number;
    fee: number;
  }>;
  [key: string]: any;
}

interface BuildingInfo {
  name?: string;
  roominfo?: {
    room_number?: string;
    elec?: {
      lighting?: string[];
      motive?: string[];
    };
  }[];
}

interface ApiResponse {
  bills: Bill[];
  message?: string;
}

interface SearchElectricityBillProps {
  user: UserAttributes | null;
}

interface BillChartData {
  [key: string]: string | number;
  name: string;
  total: number;
  usage: number;
}


const SearchElectricityBill: React.FC<SearchElectricityBillProps> = ({
  user,
}) => {
  const getDefaultDate = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    // 25日以前なら前月を、それ以降なら当月を設定
    if (currentDay < 25) {
      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    const year = currentDate.getFullYear().toString();
    //const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString();

    return { year, month };
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString();
    const month = (currentDate.getMonth() + 1).toString();
    return { year, month };
  };

  // デフォルト値を設定してから state を初期化
  const defaultDate = getDefaultDate();
  const currentDate = getCurrentDate();

  const [queryType, setQueryType] = useState("date");
  const [roomNumber, setRoomNumber] = useState("");
  const [billData, setBillData] = useState<Bill[] | null>(null);
  const [error, setError] = useState("");
  const [year, setYear] = useState(defaultDate.year);
  const [month, setMonth] = useState(defaultDate.month);
  const [maxYear, setMaxYear] = useState(defaultDate.year);
  const [maxMonth, setMaxMonth] = useState(defaultDate.month);
  const [basicBillInfo, setBasicBillInfo] = useState<BasicBillInfo | null>(null);
  const [startYear, setStartYear] = useState(defaultDate.year);
  const [startMonth, setStartMonth] = useState(defaultDate.month);
  const [endYear, setEndYear] = useState(defaultDate.year);
  const [endMonth, setEndMonth] = useState(defaultDate.month);
  const [chartData, setChartData] = useState<BillChartData[]>([]);
  const [buildingInfo, setBuildingInfo] = useState<BuildingInfo | null>(null);
  
  useEffect(() => {
    console.log('call useEffect');
  
    const fetchBuildingInfo = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        if (!token) {
          throw new Error("認証トークンが利用できません");
        }
        const restOperation = get({
          apiName: "billapi",
          path: "/get_building_info",
        });
        const { body, statusCode } = await restOperation.response;
        const result = (await body.json()) as BuildingInfo;
        if (statusCode === 200) {
          setBuildingInfo(result);
        } else {
          setError("ビル情報の取得に失敗しました。");
        }
      } catch (err) {
        console.error("ビル情報の取得エラー:", err);
        setError("ビル情報の取得中にエラーが発生しました。");
      }
    };
    if (user?.admin) {
      fetchBuildingInfo();
    }

    // if (billData && billData.length > 0 && queryType === 'room') {
    //   const sortedData = [...billData].sort((a, b) => {
    //     const aDate = new Date(a.year, a.month - 1);
    //     const bDate = new Date(b.year, b.month - 1);
    //     return aDate.getTime() - bDate.getTime();
    //   });

    //   const formattedData = sortedData.map(bill => {
    //     const total = bill.electric_meter.reduce(
    //       (sum, meter) => sum + (meter.fee || 0),
    //       0
    //     );
    //     const usage = bill.electric_meter.reduce(
    //       (sum, meter) => sum + (meter.diff || 0),
    //       0
    //     );
    //     return {
    //       name: `${bill.year}/${String(bill.month).padStart(2, '0')}`,
    //       total: total,
    //       usage: usage
    //     };
    //   });

    //   console.log('Debug - Final formatted data:', {
    //     formattedData,
    //     length: formattedData.length
    //   });    

    //   setChartData(formattedData);
    // }
  }, [user]);

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newYear = e.target.value;
    setYear(newYear);
    
    if (newYear === maxYear) {
      setMonth(prev => Math.min(parseInt(prev), parseInt(maxMonth)).toString().padStart(2, '0'));
    }
  };

  const formatDateString = (year: string, month: string) => {
    return `${year}${month.padStart(2, '0')}`;
  };

  const getCustomHeaders = async () => {
    try {
      const session = await fetchAuthSession();
      return session.tokens?.idToken?.toString();
    } catch (error) {
      console.error("Error fetching auth session:", error);
      return "";
    }
  };

  const formatValue = (value: string | number | undefined): string => {
    if (value === undefined || value === null || value === '') {
      return "0";
    }
    return value.toString();
  };

  const fetchBillData = async () => {
    setError("");
    setBillData(null);
    setBasicBillInfo(null);

    const token = await getCustomHeaders();
    if (!token) {
      setError("認証エラー: トークンが利用できません");
      return;
    }

    let path = "";
    if (queryType === "date") {
      if (!year || !month) {
        setError("年と月を入力してください");
        return;
      }
      await fetchBasicBillInfo(year, month)
      path = `/electricity-bills/${year}/${month.padStart(2, "0")}`;
    } else {
      if (!roomNumber) {
        setError("部屋番号を入力してください");
        return;
      }
      if (!startYear || !startMonth || !endYear || !endMonth) {
        path = `/electricity-bills/room/${roomNumber}`;
      } else {
        const startDate = formatDateString(startYear, startMonth);
        const endDate = formatDateString(endYear, endMonth);
        path = `/electricity-bill?room=${roomNumber}&start_date=${startDate}&end_date=${endDate}`;
      }
    }
    console.log("HERE")

    try {
      const response = await get({
        apiName: "billapi",
        path: path,
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      });
      const { body, statusCode } = await response.response;
      const result = (await body.json()) as unknown as ApiResponse;
      
      if (statusCode === 200 && result && Array.isArray(result.bills)) {
        setBillData(result.bills);
        console.log("HERE2", queryType, result.bills)
        // ここに追加
        if (result.bills && result.bills.length > 0 && queryType === 'room') {
          const sortedData = [...result.bills].sort((a, b) => {
            const aDate = new Date(a.year, a.month - 1);
            const bDate = new Date(b.year, b.month - 1);
            return aDate.getTime() - bDate.getTime();
          });
    
          const formattedData = sortedData.map(bill => {
            const total = bill.electric_meter.reduce(
              (sum, meter) => sum + (meter.fee || 0),
              0
            );
            const usage = bill.electric_meter.reduce(
              (sum, meter) => sum + (meter.diff || 0),
              0
            );
            return {
              name: `${bill.year}/${String(bill.month).padStart(2, '0')}`,
              total: total,
              usage: usage
            };
          });
          console.log("HERE3")
    
          console.log('Debug - Final formatted data:', {
            formattedData,
            length: formattedData.length
          });    
    
          setChartData(formattedData);
        }
        //ここに追加した


      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
      setError("データの取得中にエラーが発生しました");
    }
  };

  const fetchBasicBillInfo = async (year: string, month: string) => {
    const token = await getCustomHeaders();
    if (!token) {
      setError("認証エラー: トークンが利用できません");
      return;
    }

    try {
      const response = await get({
        apiName: "billapi",
        path: `/electricity-basicbill/${year}/${month.padStart(2, "0")}`,
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      });
      
      const { body, statusCode } = await response.response;
      const result = (await body.json()) as unknown as BasicBillInfo;
      
      if (statusCode === 200) {
        const result = (await body.json()) as unknown as BasicBillInfo;
        setBasicBillInfo(result);
      } else {
        setError("基本料金情報の取得に失敗しました");
      }
    } catch (error: any) {
      console.error("Error fetching basic bill info:", error);
      console.error(error._response?.statusCode, JSON.parse(error._response.body))
      setError("基本料金情報の取得中にエラーが発生しました");
    }
  };

  const renderBasicBillInfo = () => {
    if (!basicBillInfo) return null;
    
    const rows = [
      { label: "基本料", value: `${basicBillInfo.basic_price.toFixed(2)} 円` },
      { label: "総使用量", value: `${basicBillInfo.total_usage.toFixed(2)} kWh` },
      { label: "電力単価", value: `${basicBillInfo.electricity_unit_price.toFixed(2)} 円/kWh` },
      { label: "再生可能エネルギー単価", value: `${basicBillInfo.renewable_energy_unit_price.toFixed(2)} 円/kWh` },
      { label: "保守料", value: `${basicBillInfo.maintenance_price.toFixed(2)} 円` },
      { label: "総額", value: `${basicBillInfo.total_price.toFixed(2)} 円` },
      { label: "1kWhあたりの電気代", value: `${basicBillInfo.unit_per_fee.toFixed(2)} 円` },
    ];

    return (
      <Card sx={{ mt: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {basicBillInfo.year}年{basicBillInfo.month}月の基本料金情報
          </Typography>
          <Table size="small">
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  };

  const renderRoomInput = () => {
    if (user?.admin) {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>部屋番号</InputLabel>
          <Select
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            label="部屋番号"
          >
            <MenuItem value="">
              <em>部屋を選択してください</em>
            </MenuItem>
            {buildingInfo?.roominfo?.map((room) => (
              <MenuItem key={room.room_number} value={room.room_number}>
                {room.room_number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (user?.room && user.room.length === 1) {
      return (
        <Typography variant="body1" sx={{ my: 2 }}>
          部屋番号: {user.room[0]}
        </Typography>
      );
    } else if (user?.room && user.room.length > 0) {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>部屋を選択</InputLabel>
          <Select
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            label="部屋を選択"
          >
            <MenuItem value="">
              <em>部屋を選択してください</em>
            </MenuItem>
            {user.room.map((room) => (
              <MenuItem key={room} value={room}>
                {room}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          利用可能な部屋がありません
        </Alert>
      );
    }
  };

  const renderDateSelectionFields = (
    yearValue: string,
    monthValue: string,
    onYearChange: (value: string) => void,
    onMonthChange: (value: string) => void,
    label: string
  ) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          label="年"
          value={yearValue}
          onChange={(e) => onYearChange(e.target.value)}
          size="small"
        >
          {Array.from({ length: parseInt(maxYear) - 2022 }, (_, i) => (
            <MenuItem key={2023 + i} value={(2023 + i).toString()}>
              {2023 + i}年
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          fullWidth
          label="月"
          value={monthValue}
          onChange={(e) => onMonthChange(e.target.value)}
          size="small"
        >
          {Array.from({ length: 12 }, (_, i) => (
            <MenuItem key={i + 1} value={(i + 1).toString()}>
              {i + 1}月
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );

  // 部屋番号検索のフォーム部分を修正
  const renderRoomSearchFields = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {renderRoomInput()}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderDateSelectionFields(
          startYear,
          startMonth,
          setStartYear,
          setStartMonth,
          "開始年月"
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderDateSelectionFields(
          endYear,
          endMonth,
          setEndYear,
          setEndMonth,
          "終了年月"
        )}
      </Grid>
    </Grid>
  );

  const renderBillTable = (bills: Bill[]) => {
    if (bills.length === 0) return null;
    
    return (
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>部屋番号</TableCell>
              <TableCell>年月</TableCell>
              <TableCell>メータ</TableCell>
              <TableCell align="right">合計</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((bill, index) => {
              const total = bill.electric_meter.reduce(
                (sum, meter) => sum + (meter.fee || 0),
                0
              );
  
              return (
                <TableRow key={index}>
                  <TableCell>{bill.room}</TableCell>
                  <TableCell>{`${formatValue(bill.year)}年${formatValue(bill.month)}月`}</TableCell>
                  <TableCell>
                    <Table size="small">
                      <TableBody>
                        {bill.electric_meter.length > 0 ? (
                          bill.electric_meter.map((meter, meterIndex) => (
                            <TableRow key={meterIndex}>
                              <TableCell>{formatValue(meter.name)}</TableCell>
                              <TableCell>{`${formatValue(meter.value)}kW`}</TableCell>
                              <TableCell>{`${formatValue(meter.fee)}円`}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell>使用データ無し</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1" fontWeight="bold">
                      {`${formatValue(total)}円`}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderCharts = () => {
    console.log('renderCharts called:', {
      queryType,
      chartDataLength: chartData?.length,
      chartData
    });
  
    if (!chartData || chartData.length === 0) return null;
  
    return (
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          電気使用量と料金の推移
        </Typography>
        <ChartComponent chartData={chartData} />
      </Paper>
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          電気代検索
        </Typography>

        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel component="legend">検索方法</FormLabel>
          <RadioGroup
            row
            value={queryType}
            onChange={(e) => {
              setQueryType(e.target.value)
              // 関連するステートをクリア
              setBillData(null);
              setChartData([]);
              setBasicBillInfo(null);
              setError("");
              
              // queryType変更時に関連する入力もリセット
              if (e.target.value === "date") {
                // 日付検索に切り替わった場合
                const defaultDate = getDefaultDate();
                setYear(defaultDate.year);
                setMonth(defaultDate.month);
              } else {
                // 部屋番号検索に切り替わった場合
                if (!user?.admin) {
                  // 管理者でない場合は、デフォルトの部屋番号を設定
                  setRoomNumber(user?.room?.[0] || "");
                } else {
                  setRoomNumber("");
                }
                // 期間もデフォルトに戻す
                const defaultDate = getDefaultDate();
                setStartYear(defaultDate.year);
                setStartMonth(defaultDate.month);
                setEndYear(defaultDate.year);
                setEndMonth(defaultDate.month);
              }
            }}
            >
            <FormControlLabel
              value="date"
              control={<Radio />}
              label="年月で検索"
            />
            <FormControlLabel
              value="room"
              control={<Radio />}
              label="部屋番号で検索"
            />
          </RadioGroup>
        </FormControl>

        {queryType === "date" ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="年"
                value={year}
                onChange={handleYearChange}
                size="small"
              >
                {Array.from({ length: parseInt(maxYear) - 2022 }, (_, i) => (
                  <MenuItem key={2023 + i} value={(2023 + i).toString()}>
                    {2023 + i}年
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="月"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                size="small"
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i + 1} value={(i + 1).toString()}>
                    {i + 1}月
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : (
          renderRoomSearchFields()
        )}

        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchBillData}
            fullWidth
          >
            検索
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {billData && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              検索結果: {billData.length}件
            </Typography>
            {basicBillInfo && renderBasicBillInfo()}
            {billData.length > 0 ? (
              <>
                {renderBillTable(billData)}
                {queryType === 'room' && renderCharts()}
              </>
            ) : (
              <Alert severity="info" sx={{ mt: 2 }}>
                該当する請求書データがありません。
              </Alert>
            )}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default SearchElectricityBill;