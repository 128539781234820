import React, { useState } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";

interface LoginProps {
  onLoginSuccess: () => void;
}

const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [challengeName, setChallengeName] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });

      if (isSignedIn) {
        onLoginSuccess();
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setChallengeName("NEW_PASSWORD_REQUIRED");
      }
    } catch (error) {
      console.error("Error signing in", error);
      setError(
        "ログインに失敗しました。ユーザー名とパスワードを確認してください。"
      );
    }
  };

  const handleNewPasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: newPassword,
      });

      if (isSignedIn) {
        onLoginSuccess();
      }
    } catch (error) {
      console.error("Error confirming sign in", error);
      setError("新しいパスワードの設定に失敗しました。");
    }
  };

  if (challengeName === "NEW_PASSWORD_REQUIRED") {
    return (
      <div className="login-container">
        <h2>新しいパスワードを設定してください</h2>
        <form onSubmit={handleNewPasswordSubmit}>
          <div>
            <label htmlFor="newPassword">新しいパスワード:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">パスワードを変更</button>
        </form>
      </div>
    );
  }

  return (
    <div className="login-container">
      <h2>ログイン</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">ユーザー名:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">パスワード:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">ログイン</button>
      </form>
    </div>
  );
};

export default Login;
