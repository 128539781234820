import { useState } from 'react';
import {
  BarChart,
  LineChart,
} from '@mui/x-charts';

interface CustomChartData {
  [key: string]: string | number;
  name: string;
  total: number;
  usage: number;
}

interface ChartComponentProps {
  chartData: CustomChartData[];
}

const ChartComponent: React.FC<ChartComponentProps> = ({ chartData }) => {
  if (!chartData || chartData.length === 0) return null;

  // null を考慮した valueFormatter の定義
  const formatCurrency = (value: number | null) => {
    if (value === null) return '---';
    return `${value.toLocaleString()}円`;
  };

  const formatUsage = (value: number | null) => {
    if (value === null) return '---';
    return `${value}kWh`;
  };

  return (
    <div className="w-full space-y-8">
      <div>
        <h3 className="text-lg font-semibold mb-4">月別電気料金</h3>
        <div className="w-full h-[300px]">
          <BarChart
            dataset={chartData as any[]}
            series={[
              {
                dataKey: 'total',
                label: '電気料金',
                valueFormatter: formatCurrency,
              },
            ]}
            xAxis={[{
              scaleType: 'band',
              dataKey: 'name',
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12
              }
            }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            height={300}
          />
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">月別使用量と料金の推移</h3>
        <div className="w-full h-[300px]">
          <LineChart
            dataset={chartData as any[]}
            series={[
              {
                dataKey: 'total',
                label: '電気料金',
                valueFormatter: formatCurrency,
              },
              {
                dataKey: 'usage',
                label: '使用量',
                valueFormatter: formatUsage,
              }
            ]}
            xAxis={[{
              scaleType: 'point',
              dataKey: 'name',
              tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12
              }
            }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 40 }}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;