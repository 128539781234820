import React, { useState, useEffect } from "react";
import { UserAttributes } from "./UserContext";

interface ProfileProps {
  user: UserAttributes | null;
}

const Profile: React.FC<ProfileProps> = ({ user }) => {
  if (!user) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div>
      <h2>プロフィール</h2>
      {user.email && <p>Email: {user.email}</p>}
      <p>{user.admin ? "管理者" : "住民"}</p>
      {!user.admin && Array.isArray(user.room) && user.room.length > 0 && (
        <p>管理部屋番号: {user.room.join(", ")}</p>
      )}
    </div>
  );
};

export default Profile;
