import React, { useState, ChangeEvent, FormEvent } from "react";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

interface FormData {
  year: string;
  month: string;
  contract_kw: string;
  voltage_kv: string;
  base_fee: string;
  rate_other: string;
  rate_summer: string;
  fuel_adjust: string;
  new_energy_fee: string;
  maintenance: string;
}

interface Status {
  type: "success" | "error" | "";
  message: string;
}

const fieldMapping: { [K in keyof FormData]: string } = {
  year: "年",
  month: "月",
  contract_kw: "契約電力(kW)",
  voltage_kv: "電圧(kV)",
  base_fee: "基本料金",
  rate_other: "他季単価(¥/W)",
  rate_summer: "夏季単価(¥/W)",
  fuel_adjust: "燃料費調整単価(¥/W)",
  new_energy_fee: "再生可能エネルギー促進賦課金単価(¥/W)",
  maintenance: "保守代金(¥)"
};

const ElectricityRateForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    year: "",
    month: "",
    contract_kw: "",
    voltage_kv: "",
    base_fee: "",
    rate_other: "",
    rate_summer: "",
    fuel_adjust: "",
    new_energy_fee: "",
    maintenance: "",
  });

  const [status, setStatus] = useState<Status>({ type: "", message: "" });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus({ type: "", message: "" });

    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      if (!token) {
        throw new Error("認証トークンが利用できません");
      }
      const restOperation = post({
        apiName: "billapi",
        path: "/electricity-rate",
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: {
            year: formData.year,
            month: formData.month,
            contract_kw: formData.contract_kw,
            voltage_kv: formData.voltage_kv,
            base_fee: formData.base_fee,
            rate_other: formData.rate_other,
            rate_summer: formData.rate_summer,
            fuel_adjust: formData.fuel_adjust,
            new_energy_fee: formData.new_energy_fee,
            maintenance: formData.maintenance,
          },
        },
      });
      const { body, statusCode } = await restOperation.response;
      const result = (await body.json())

      if (statusCode === 200) {
        setStatus({
          type: "success",
          message: "電気基本情報が正常に登録されました",
        });
      } else {
        console.log("電気代登録失敗:", result);
        setStatus({
          type: "error",
          message: "電気代の登録中にエラーが発生しました。",
        });
      }
    } catch (error) {
      console.error("ネットワークエラー:", error);
      setStatus({
        type: "error",
        message: "ネットワークエラーが発生しました。",
      });
    }
  };


  return (
    <div>
      <h2>電気基本情報登録</h2>
      <form onSubmit={handleSubmit}>
        {(Object.keys(formData) as Array<keyof FormData>).map((key) => (
          <div key={key}>
            <label htmlFor={key}>{fieldMapping[key]}:</label>
            <input
              type="text"
              id={key}
              name={key}
              value={formData[key]}
              onChange={handleChange}
              required
            />
          </div>
        ))}
        <button type="submit">登録</button>
      </form>
      {status.message && (
        <div>
          <h3>{status.type === "error" ? "エラー" : "成功"}</h3>
          <p>{status.message}</p>
        </div>
      )}
    </div>
  );
};

export default ElectricityRateForm;
