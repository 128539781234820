import React, { useState, useEffect } from "react";
import "./App.css";
import { Amplify, ResourcesConfig } from "aws-amplify";
import {
  signOut,
  getCurrentUser,
  fetchAuthSession,
} from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import Login from "./components/Login";
import RegisterUser from "./components/RegisterUser";
import SetElectricityBill from "./components/SetElectricityBill";
import SearchElectricityBill from "./components/SearchElectricityBill"
import Profile from "./components/Profile";
import PasswordReset from "./components/PasswordReset";
import ElectricityRateForm from "./components/ElectricityRateForm"
import { UserAttributes, fetchUserProfile } from "./components/UserContext";
import FileUploader from "./components/FileUploader";
import Dashboard from "./components/Dashboard";
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Divider,
  Fade,
  Grow,
} from '@mui/material';


const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_ID as string,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_CLIENT_ID as string,
      signUpVerificationMethod: "code",
      loginWith: {
        email: true,
        phone: false,
        username: true,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_APP_URL as string,
          scopes: [
            "openid",
            "email",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [process.env.REACT_APP_FRONT_ENDPOINT_URL as string],
          redirectSignOut: [process.env.REACT_APP_FRONT_ENDPOINT_URL as string],
          responseType: "code",
        },
      },
    },
  },
  API: {
    REST: {
      billapi: {
        endpoint: process.env.REACT_APP_API_ENDPOINT_URL as string,
        region: "ap-northeast-1",
      },
    },
  },
};
Amplify.configure(awsExports);

interface UploadedData {
  [key: string]: {
    unit: string;
    // その他の必要なプロパティ
  };
}

function App() {
  const [user, setUser] = useState<UserAttributes | null>(null);
  const [uploadedData, setUploadedData] = useState<UploadedData | null>(null);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    document.title = "ビル管理くん";
    checkAuthStatus();
  }, []);

  const loadUserProfile = async () => {
    const userProfile = await fetchUserProfile();
    if (userProfile) {
      setUser(userProfile);
    } else {
    }
  };

  async function checkAuthStatus() {
    try {
      await getCurrentUser();
      loadUserProfile()
      setShowContent(true);
    } catch {
      setUser(null);
      setShowContent(false);
    }
  }

  async function handleSignIn() {
    try {
      loadUserProfile()
      setShowContent(true);
    } catch (error) {
      console.error("Error signing in", error);
    }
  }
  async function handleSignOut() {
    try {
      setShowContent(false);
      await signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  }
  async function callPublicApi() {
    console.log("callPublicApi");
    const operation = get({
      apiName: "billapi",
      path: "/",
    });
    try {
      const response = await operation.response;
      const body = await response.body.json();
      console.log("API Response:", body);
      // setPublicApiResponse(JSON.stringify(body, null, 2));
    } catch (error) {
      console.error("Error calling API:", error);
      // setPublicApiResponse('Error calling API: ' + JSON.stringify(error));
    }
  }
  async function getCustomHeaders() {
    console.log("getCustomHeaders");
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      return token;
    } catch (error) {
      console.error("Error fetching auth session:", error);
      return "";
    }
  }
  function callPrivateApi() {
    console.log("callPrivateApi");

    return getCustomHeaders()
      .then((token) => {
        if (!token) {
          throw new Error("No authentication token available");
        }
        return get({
          apiName: "billapi",
          path: "/private_check",
          options: {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        });
      })
      .then((apiResponse) => apiResponse.response)
      .then((response) => response.body.json())
      .then((responseBody) => {
        console.log("API Response:", responseBody);
        //setPublicApiResponse(JSON.stringify(responseBody, null, 2));
      })
      .catch((error) => {
        console.error("Error calling API:", error);
        //setPublicApiResponse('Error calling API: ' + JSON.stringify(error));
      });
  }
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <div className="App">
          <section className="Main-contents">
            {!user && (
              <Paper elevation={2} sx={{ p: 3, mt: 4 }}>
                <Container maxWidth="lg">
                  <Typography variant="h5" component="h1">
                    Bill Management System
                  </Typography>
                </Container>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Login onLoginSuccess={handleSignIn} />
                  <Divider sx={{ mb: 3 }} />
                  <PasswordReset></PasswordReset>
                </Box>
              </Paper>
            )}
            
            {user && showContent && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
                <Dashboard
                  user={user}
                  onSignOut={handleSignOut}
                />
              </Box>
            )}
          </section>
        </div>
      </Container>
    </Box>
  );
  
}

export default App;
