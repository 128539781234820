import React, { useState, useCallback, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';

interface UploadProps {
  type: string;
  year: string;
  month: string;
  room: string;
  onUploadSuccess: (result: any) => void;
}

interface PresignedUrlResponse {
  presigned_url: string;
}

interface AuthTokens {
  accessToken: string;
  idToken: string;
}

interface WebSocketMessage {
  status: string;
  result: string;
}

const FileUploader: React.FC<UploadProps> = ({ type, year, month, room, onUploadSuccess }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const socketRef = useRef<WebSocket | null>(null);
  const [result, setResult] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const getAuthToken = async (): Promise<AuthTokens> => {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken?.toString();
    const idToken = session.tokens?.idToken?.toString();
    if (!accessToken || !idToken) {
      throw new Error("認証トークンが利用できません");
    }
    return { accessToken, idToken };;
  };

  const getPresignedUrl = async (tokens: AuthTokens): Promise<string> => {
    try {
      const restOperation = post({
        apiName: "billapi",
        path: "/request-upload-url",
        options: {
          // headers: {
          //   Authorization: `Bearer ${tokens.idToken}`,
          //   "Content-Type": "application/json",
          // },
          body: {
            type,
            year,
            month,
            room,
            token: tokens.accessToken,
          },
        },
      });

      const { body, statusCode } = await restOperation.response;
      const result = (await body.json()) as unknown as PresignedUrlResponse;

      if (statusCode !== 200 || !result.presigned_url) {
        throw new Error("プリサインドURL取得に失敗しました");
      }

      return result.presigned_url;
    } catch (error) {
      console.error('Error getting presigned URL:', error);
      throw error;
    }
  };

  const uploadFile = useCallback(async () => {
    if (!file) return;

    setUploading(true);
    setError(null);
    setUploadComplete(false);
    setResult(null);

    try {
      const tokens = await getAuthToken();
      const presignedUrl = await getPresignedUrl(tokens);

      // WebSocket接続
      socketRef.current = new WebSocket(`wss://billapi-socket.yoshio.dev?token=${tokens.accessToken}`);
      
      socketRef.current.onopen = async () => {
        console.log('WebSocket接続が確立されました');

        try {
          const response = await fetch(presignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
              'x-amz-meta-type': type,
              'x-amz-meta-year': year,
              'x-amz-meta-month': month,
              'x-amz-meta-room': room,
              'x-amz-meta-token': tokens.accessToken,
            },
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error('Upload failed:', response.status, response.statusText, errorText);
            throw new Error(`Upload failed: ${response.status} ${response.statusText}`);
          }

          console.log('File uploaded successfully');
        } catch (error) {
          console.error('ファイルアップロードエラー:', error);
          setError('ファイルのアップロード中にエラーが発生しました。');
          if (socketRef.current) socketRef.current.close();
        }
      };

      socketRef.current.onmessage = (event) => {
        const message: WebSocketMessage = JSON.parse(event.data);
        if (message.status == "complete")
        {
          setUploadComplete(true);
          setUploading(false);
          setResult(message.result);
          onUploadSuccess(JSON.parse(message.result));
        }
        if (socketRef.current) socketRef.current.close();
      };

      socketRef.current.onclose = () => {
        console.log('WebSocket接続が閉じられました');
        setUploading(false);
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocketエラー:', error);
        setError('WebSocket接続中にエラーが発生しました。');
        setUploading(false);
      };

    } catch (error) {
      console.error('アップロードエラー:', error);
      setError('アップロードプロセス中にエラーが発生しました。');
      setUploading(false);
    }
  }, [file, type, year, month, room, onUploadSuccess]);

  return (
    <div>
      {/* <h2>メータ画像ファイル解析</h2> */}
      <input type="file" onChange={handleFileChange} />
      <button onClick={uploadFile} disabled={!file || uploading}>
        {uploading ? 'アップロード中...' : 'アップロード'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {/* {uploadComplete && <p style={{ color: 'green' }}>解析が完了しました</p>}
      {result && (
        <div>
          <pre>{result}</pre>
        </div>
      )} */}
    </div>
  );
};

export default FileUploader;