import React, { useState } from 'react';
import { 
  IconButton, 
  Menu, 
  MenuItem,
  Typography,
  Divider,
  ListItemText,
  ListItemIcon,
  Box,
  Stack
} from '@mui/material';
import { 
  AccountCircle,
  Logout as LogoutIcon
} from '@mui/icons-material';
import { UserAttributes } from "./UserContext";

interface ProfileMenuProps {
  user: UserAttributes;
  onSignOut: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ user, onSignOut }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="large"
        color="inherit"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 2, py: 1, minWidth: 250 }}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" fontWeight="bold">
              プロフィール
            </Typography>
            {user.email && (
              <Typography variant="body2" color="text.secondary">
                {user.email}
              </Typography>
            )}
            <Typography variant="body2">
              {user.admin ? "管理者" : "住民"}
            </Typography>
            {!user.admin && Array.isArray(user.room) && user.room.length > 0 && (
              <Typography variant="body2">
                管理部屋番号: {user.room.join(", ")}
              </Typography>
            )}
          </Stack>
        </Box>
        <Divider />
        <MenuItem onClick={onSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>ログアウト</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;