import React, { useState } from "react";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

interface RegisterUserProps {
  onRegistrationSuccess: () => void;
}

interface ApiResponse {
  error?: string;
  message?: string;
}

const RegisterUser: React.FC<RegisterUserProps> = ({
  onRegistrationSuccess,
}) => {
  const [email, setEmail] = useState("");
  // const [temporaryPassword, setTemporaryPassword] = useState("");
  const [room, setRoom] = useState("");
  const [isAdmin, setIsAdmin] = useState("false");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString();

    if (!token) {
      throw new Error("認証トークンが利用できません");
    }

    try {
      const restOperation = post({
        apiName: "billapi",
        path: "/user/register",
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: {
            email,
            // temporary_password: temporaryPassword,
            room,
            isAdmin: isAdmin,
          },
        },
      });

      const { body, statusCode } = await restOperation.response;
      const result = (await body.json()) as ApiResponse;
      console.error(statusCode);

      if (statusCode !== 200) {
        if (
          typeof result === "object" &&
          result !== null &&
          "error" in result
        ) {
          setError(result.error || "ユーザー登録に失敗しました");
        } else {
          setError("ユーザー登録に失敗しました");
        }
        console.log("User registered:", result);
      } else {
        console.log("User registered:", result);
        onRegistrationSuccess();
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError(
        error instanceof Error ? error.message : "未知のエラーが発生しました"
      );
    }
  };

  return (
    <div className="register-container">
      <h2>新規ユーザー登録</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">メールアドレス:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* <div>
          <label htmlFor="temporaryPassword">パスワード:</label>
          <input
            type="password"
            id="temporaryPassword"
            value={temporaryPassword}
            onChange={(e) => setTemporaryPassword(e.target.value)}
            required
          />
        </div> */}
        <div>
          <label htmlFor="room">登録する部屋:</label>
          <input
            type="text"
            id="room"
            value={room}
            onChange={(e) => setRoom(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="isAdmin">
            管理者権限
            <input
              type="checkbox"
              id="isAdmin"
              checked={isAdmin === "true"}
              onChange={(e) => setIsAdmin(e.target.checked ? 'true': 'false')}
            />
          </label>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">ユーザーを登録</button>
      </form>
    </div>
  );
};

export default RegisterUser;
