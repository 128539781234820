import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";

export interface UserAttributes {
  email?: string;
  admin?: boolean;
  room?: string[];
}

export const fetchUserProfile = async (): Promise<UserAttributes | null> => {
  try {
    const currentUser = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();
    return {
      email: userAttributes.email,
      admin: userAttributes["custom:isAdmin"] === "true",
      room: userAttributes["custom:room"]
        ? userAttributes["custom:room"].split(",").map((room) => room.trim())
        : undefined,
    };
  } catch (err) {
    console.error("Error fetching user profile:", err);
    return null;
  }
};
