import React, { useState } from "react";
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';

const PasswordReset: React.FC = () => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');

  const handleForgotPassword = async () => {
    try {
      await resetPassword({username});
      setStep(2);
    } catch (err) {
      setError('パスワードリセットの開始に失敗しました。');
      console.error('Forgot password error', err);
    }
  };

  const handleConfirmNewPassword = async () => {
    try {
      await confirmResetPassword({ username, confirmationCode:code, newPassword })
      setStep(3);
    } catch (err) {
      setError('新しいパスワードの設定に失敗しました。');
      console.error('Confirm password error', err);
    }
  };

  return (
    <div>
      {step === 1 && (
        <div>
          <h2>パスワードをリセット</h2>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="ユーザー名またはメールアドレス"
          />
          <button onClick={handleForgotPassword}>リセットコードを送信</button>
        </div>
      )}   
      {step === 2 && (
        <div>
          <h2>新しいパスワードを設定</h2>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="届いた確認コード"
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="新しいパスワード"
          />
          <button onClick={handleConfirmNewPassword}>パスワードを更新</button>
        </div>
      )}
      
      {step === 3 && (
        <div>
          <h2>パスワードが更新されました</h2>
          <p>新しいパスワードでログインしてください。</p>
        </div>
      )}
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PasswordReset;